/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import NoSSR from "react-no-ssr"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import BackgroundSlider from "react-background-slider"
import PageWrapper from "../../layouts/PageWrapper"
import EngineerImg1 from "../../img/engineer/img1.png"
import EngineerImg2 from "../../img/engineer/img2.png"
import EngineerImg3 from "../../img/engineer/img3.png"
import HomeLogo from "../../components/HomeLogo"

class CorePage extends Component {
  componentDidMount() {
    window.onpageshow = function () {
      const slider = document.getElementById("ReactBackgroundSlider")
      if (slider) {
        slider.style.height = `${
          document.getElementById("master").scrollHeight
        }px`
        slider.style.width = "100vw"
        slider.style.position = "absolute"
      }
    }
    setTimeout(function () {
      const slider = document.getElementById("ReactBackgroundSlider")
      if (slider) {
        slider.style.height = `${
          document.getElementById("master").scrollHeight
        }px`
        slider.style.width = "100vw"
        slider.style.position = "absolute"
      }
    }, 500)
  }

  render() {
    return (
      <PageWrapper>
        <section className="hero is-fullheight" id="master">
          <div>
            <div id="core_slider-desktop">
              <NoSSR>
                <BackgroundSlider
                  images={[EngineerImg1, EngineerImg2, EngineerImg3]}
                  duration={5}
                  transition={3}
                />
              </NoSSR>
            </div>
            <Helmet>
              <title>Core Businesses - E.A. Technique</title>
            </Helmet>
            <section className="hero is-small">
              <div className="hero-body">
                <div className="container">
                  <div className="columns">
                    <div className="column">
                      <HomeLogo />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section is-paddingless" id="core_page">
              <div id="core_slider-mobile">
                <NoSSR>
                  <BackgroundSlider
                    images={[EngineerImg1, EngineerImg2, EngineerImg3]}
                    duration={5}
                    transition={3}
                  />
                </NoSSR>
              </div>
              <div className="container">
                <Tabs defaultIndex={1}>
                  <div className="core columns is-multiline">
                    <div className="column is-6-desktop is-12-tablet">
                      <div
                        className="box"
                        id="core-box"
                        style={{
                          opacity: 0.9,
                        }}
                      >
                        <TabPanel />
                        <TabPanel>
                          <h3 className="title is-primary is-3">
                            Marine Engineering Service
                          </h3>
                          <p style={{ textAlign: "justify", fontSize: "14px" }}>
                            Our marine engineering services are more focused to the shipbuilding and ship repair activities. 
                            <br />
                            <br />
                            Our shipbuilding and ship repair activities serve as an internal supporting arm to our marine vessels.
                            <br />
                            <br />
                            •Shipbuilding: Some of the shipbuilding activities that we carry out include construction of hull and structure, installation of machinery, equipment and instruments, and various embedded systems on the deck of the vessel, painting, and coating, as well as testing and commissioning.
                            <br />
                            <br />
                            •Ship repair: Our ship repair utilizes the same facilities, equipment and skill set as shipbuilding. Our repair works involves inspection, replacement, modification, removal, installation, and cleaning.
                            <br />
                            <br />
                            We also undertake the minor fabrication of steel structures in our shipyard. The steel structures that we fabricate are mainly for marine vessels for example skids and piping systems.
                          </p>
                        </TabPanel>
                        <TabPanel />
                      </div>
                    </div>
                    <div className="column is-3-desktop is-9-tablet is-offset-3 core_page-tabs-container">
                      <TabList className="react-tabs__tab-list-primary core_page-tabs-list orange">
                        <Link to="/core">
                          <Tab>
                            Marine Transportation and Offshore Storage of Oil &
                            Gas
                          </Tab>
                        </Link>
                        <Link to="/core/engineer">
                          <Tab>Marine Engineering Service</Tab>
                        </Link>
                        <Link to="/core/port">
                          <Tab>Provision of Port Marine Services</Tab>
                        </Link>
                      </TabList>
                    </div>
                  </div>
                </Tabs>
              </div>
            </section>
          </div>
        </section>
      </PageWrapper>
    )
  }
}

CorePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default CorePage
