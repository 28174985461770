import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import "../css/styles.sass"
import config from "../../meta/config"
import LazyLoad from "react-lazyload"

const PageWrapper = (props) => {
  return (
    <div>
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta name="description" content={config.siteDescription} />
      </Helmet>
      <NavBar
        navbarClassName={props.navbarClassName}
        hideMenu={props.hideMenu}
      />
      <LazyLoad once>
        <div>{props.children}</div>
      </LazyLoad>
      <Footer />
    </div>
  )
}
PageWrapper.propTypes = {
  children: PropTypes.any,
  navbarClassName: PropTypes.string,
  hideMenu: PropTypes.bool,
}

export default PageWrapper
